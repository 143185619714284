import React, { useEffect, useState } from "react";
import { useBtnContext } from "../contexts";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./slot.css";
export const SlotBooking = () => {
  const [seconds, setSeconds] = useState(10);
  const { uid, redirectUrl } = useBtnContext();
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.setItem("ResumeState", 1);
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    const uid = localStorage.getItem("uid");

    if (seconds === 2) {
      (async () => {
        console.log("In");
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/onboarding/?uid=${uid}&param=onboarding-complete`
          );
          console.log(response);
          if (response.status === 200) {
            if (!redirectUrl) {
              console.log("In1", redirectUrl);
              window.location.href = `${process.env.REACT_APP_API_URL}/home?isWelcomePopUpShown=false`;
            } else {
              console.log("In2");
              console.log(redirectUrl);
              window.location.href = `${redirectUrl}&isWelcomePopUpShown=false`;
            }
          }
        } catch (error) {
          if (error.response.status === 500) {
            navigate("/internal-server-error");
          }
        }
      })();
    }
    return () => {
      clearInterval(myInterval);
    };
  }, [seconds]);

  const redirectHandler = () => {
    window.open("https://calendly.com/saarainc/onboarding", "_blank");
    window.focus();
  };

  return (
    <>
      <Helmet>
        <script>
          {`
            (function(w,d,s,l,i){
              w[l]=w[l]||[];
              w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
              var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
              j.async=true;
              j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
              f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-5FDP6LR');
          `}
        </script>
      </Helmet>
      <div className="finaly slide-down animate__animated animate__slideInUp ">
        <svg className="finaly__bg-L icon__bg">
          <use xlinkHref="assets/svg/sprite.svg#big-logo"></use>
        </svg>
        <svg className="finaly__bg-R icon__bg">
          <use xlinkHref="assets/svg/sprite.svg#big-logo"></use>
        </svg>
        <img src="assets/images/brand_logo.webp" alt="logo" className="logo" />
        <h2 className="finaly__title">
          <br />
          We are preparing your return portal based on your preference.
        </h2>

        <p className="finaly__desc">
          Meanwhile, we strongly recommend you to use our free concierge
          service. Book a free onboarding session. Clicking below will not
          disrupt your current flow.
        </p>
        <div className="btn_main">
          {/* <a
            href="https://calendly.com/saarainc/onboarding"
            target="_blank"
            onclick="redirectHandler"
            rel="noreferrer"
          > */}
          <button type="button" onClick={redirectHandler} className="book_demo">
            Book
          </button>
          {/* </a> */}
        </div>
        <div className="finaly__redirect">
          This page will automatically redirect in
          <p className="finaly__seconds timer"> {seconds} seconds</p>
        </div>
      </div>
    </>
  );
};
